export default {
  travels: {
    hasProfiles: true,
    profiles: {
      personal: {
        hasSidebar: true,
        visible: true,
      },
      approver: {
        visible: true,
      },
      financial: {
        visible: false,
      },
      director: {
        visible: true,
      },
    },
  },
  expenses: {
    hasProfiles: true,
    profiles: {
      personal: {
        hasSidebar: true,
        visible: true,
      },
      approver: {
        hasSidebar: false,
        visible: true,
      },
      financial: {
        hasSidebar: false,
        visible: true,
      },
    },
  },
  advances: {
    hasProfiles: true,
    profiles: {
      personal: {
        hasSidebar: true,
        visible: true,
      },
      approver: {
        hasSidebar: false,
        visible: true,
      },
      financial: {
        hasSidebar: true,
        visible: true,
      },
    },
  },
  transactions: {
    hasSidebar: false,
    hasProfiles: false,
    profiles: {
      personal: {
        visible: true,
      },
      approver: {
        visible: false,
      },
      financial: {
        visible: true,
      },
    },
  },
  reports: {
    hasSidebar: true,
    hasProfiles: true,
    profiles: {
      personal: {
        visible: true,
      },
      approver: {
        visible: false,
      },
      financial: {
        visible: true,
      },
    },
  },
  insights: {
    hasSidebar: false,
    hasProfiles: false,
    profiles: {
      personal: {
        visible: true,
      },
      approver: {
        visible: true,
      },
      financial: {
        visible: true,
      },
    },
  },
  admin: {
    hasSidebar: true,
    hasProfiles: false,
    profiles: {
      personal: {
        visible: false,
      },
      approver: {
        visible: false,
      },
      financial: {
        visible: false,
      },
    },
  },
  settings: {
    hasSidebar: false,
    hasProfiles: false,
    profiles: {
      personal: {
        visible: false,
      },
      approver: {
        visible: false,
      },
      financial: {
        visible: false,
      },
    },
  },
};
