import { InfoOutlined } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select,
} from "@mui/material";
import React from "react";
import LoadingSection from "../loading/LoadingSection";

function SelectInput({
  value,
  onChange,
  children,
  label,
  renderValue,
  variant,
  StartIcon,
  size,
  error,
  onBlur,
  onFocus,
  fullWidth,
  width,
  placeholder,
  disabled,
  onOpen,
  onClose,
  required,
  sx,
  helperText,
  showErrorIcon,
  className,
  readOnly,
  defaultValue,
  margin,
  endIcon,
  loading,
}) {
  return (
    <FormControl
      error={error}
      margin={margin}
      sx={{ width: width }}
      variant={variant}
      size={size}
      required={required}
      fullWidth={fullWidth || true}
    >
      <InputLabel id="default-select-label">{label}</InputLabel>
      <Select
        labelId="default-select-label"
        sx={{ ...sx, fontSize: ".93rem" }}
        MenuProps={{
          elevation: 1,
          disableScrollLock: true,
          PaperProps: {
            sx: {
              boxShadow: 5,
            },
          },
        }}
        startAdornment={
          StartIcon && (
            <InputAdornment position="start">
              <StartIcon />
            </InputAdornment>
          )
        }
        endAdornment={
          endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>
        }
        required={required}
        renderValue={renderValue}
        readOnly={readOnly}
        onOpen={onOpen}
        onClose={onClose}
        defaultValue={defaultValue}
        disabled={disabled}
        onFocus={onFocus}
        className={className}
        onBlur={onBlur}
        placeholder={placeholder}
        error={error}
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        {loading ? <LoadingSection h={100} /> : children}
      </Select>
      {error && (
        <FormHelperText
          sx={{ color: "#F24B4B", display: "flex", alignItems: "center" }}
        >
          {showErrorIcon && (
            <InfoOutlined fontSize="small" sx={{ mr: 1, ml: -1 }} />
          )}{" "}
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectInput;
