import { PostAddOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Baseline from "../../../../components/form/Baseline";
import {
  getProjects,
  selectProjectsByRole,
} from "../../../../store/features/configs/projectsSlice";
import SelectorBox from "../SelectorBox";

function ProjectInput({
  value,
  onChange,
  readOnly,
  size,
  variant = "filled",
  isEditable = true,
  margin = "dense",
  role = "personal",
}) {
  const dispatch = useDispatch();

  const {
    data,
    status,
    error: sliceError,
  } = useSelector((state) => selectProjectsByRole(state, role));

  const loading = status === "loading";

  const onOpen = () => {
    if (status === "idle") {
      dispatch(getProjects(role));
    }
  };

  if (!isEditable) {
    return (
      <Baseline
        Icon={PostAddOutlined}
        label={"Projeto"}
        value={value?.name}
        emptyLabel={"Sem projeto"}
      />
    );
  } else {
    return (
      <SelectorBox Icon={PostAddOutlined}>
        <Autocomplete
          fullWidth
          loading={loading}
          onOpen={onOpen}
          value={value || null}
          readOnly={readOnly}
          autoHighlight
          options={data || []}
          size={size || "small"}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          onChange={(e, v) => {
            onChange(v || "");
          }}
          getOptionLabel={(option) => option?.name || ""}
          renderOption={(props, option, state) => {
            return (
              <MenuItem {...props} key={option?._id} selected={state.selected}>
                <PostAddOutlined color="primary" sx={{ mr: 2 }} />
                {option?.name}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin={margin}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              variant={variant}
              label={"Projeto"}
            />
          )}
        />
      </SelectorBox>
    );
  }
}

export default memo(ProjectInput);
