import { WSS_URL } from "../urls";
import messageCapture from "./messageCapture";

class WebSockets {
  static _accessToken = "";
  static _isOnline = true;
  static _client = null;
  static _reconnectAttempts = 0;
  static _maxReconnectAttempts = 10;
  static _manualClose = false; // Identificador para fechamento manual

  static setAccessToken(token) {
    this._accessToken = token;
  }

  static setConnectionIsOnline(bool) {
    if (!bool) {
      // Marcar como desconexão manual
      this._manualClose = true;
      this._client?.close();
    }
    this._isOnline = !!bool;
  }

  static sendMessage(message) {
    if (this._client && this._client.readyState === WebSocket.OPEN) {
      this._client.send(message);
    } else {
      console.error("Cannot send message, WebSocket is not open.");
    }
  }

  static onOpen() {
    console.log("WebSocket client connected");
    this._reconnectAttempts = 0; // Resetar tentativas de reconexão
    this._manualClose = false; // Resetar o identificador de fechamento manual
  }

  static onMessage(message) {
    const dataFromServer = message.data;
    console.log("Mensagem do WSS: ", dataFromServer);
    messageCapture(dataFromServer);
  }

  static onClose(event) {
    console.log("WebSocket client disconnected", event);

    // Verificar se a desconexão foi causada manualmente ou pela aplicação
    if (!this._manualClose && this._isOnline) {
      if (this._reconnectAttempts < this._maxReconnectAttempts) {
        this._reconnectAttempts++;
        setTimeout(() => {
          console.log(`Tentando reconexão WS (${this._reconnectAttempts})`);
          this.start();
        }, 5000);
      } else {
        console.error("Max reconnection attempts reached.");
      }
    } else {
      console.warn("WebSocket disconnected manually or is offline.");
    }
  }

  static onError(error) {
    console.error("WebSocket encountered an error:", error);
  }

  static start() {
    // Fechar conexão anterior, se existir
    if (this._client && this._client.readyState !== WebSocket.CLOSED) {
      console.warn(
        "Closing existing WebSocket connection before starting a new one."
      );
      this._client.close(); // Fechar conexão anterior
    }

    console.log("Starting WebSocket connection...");
    this._manualClose = false; // Resetar o identificador de fechamento manual
    this._client = new WebSocket(`${WSS_URL}?accessToken=${this._accessToken}`);

    this._client.onopen = this.onOpen.bind(this);
    this._client.onmessage = this.onMessage.bind(this);
    this._client.onclose = this.onClose.bind(this);
    this._client.onerror = this.onError.bind(this);
  }

  static stop() {
    // Método para encerrar manualmente a conexão
    console.log("Stopping WebSocket connection...");
    this._manualClose = true; // Marcar como desconexão manual
    this._client?.close();
  }
}

export default WebSockets;
