import {
  saveAccountData,
  setAccessExpiresAt,
  setAccountError,
  updateBaseInfo,
} from "../store/features/accountSlice";
import { store } from "../store/store";
import { AuthAPIGateway, AuthClient, HttpClient } from "./httpClient";
import { AUTH_BASE_URL } from "./urls";
import WebSockets from "./websockets";

// Helper para configurar tokens de acesso
const handleSetupTokensAccess = (accessToken, expiresAt) => {
  [HttpClient, WebSockets, AuthClient].forEach((client) =>
    client.setAccessToken(accessToken)
  );
  store.dispatch(setAccessExpiresAt(expiresAt));
};

// Helper para atualizar as informações do usuário
const updateUserInfo = (data = {}) => {
  if (!data) return;
  const { email } = data;
  if (email) window.localStorage.setItem("email", email);
  store.dispatch(saveAccountData(data));
};

// Helper para autenticação do Zendesk
const authenticateZendeskUser = (zendeskToken) => {
  if (window.zE && zendeskToken) {
    window.zE("messenger", "loginUser", (callback) => callback(zendeskToken));
  }
};

// Função para lidar com autenticação em modo debug
const handleDebugAuthentication = async () => {
  try {
    const response = await fetch(`${AUTH_BASE_URL}/auth/v1/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: process.env.REACT_APP_USER,
        password: process.env.REACT_APP_PASSWORD,
        debug: true,
      }),
    });

    const data = await response.json();
    console.log(data);

    authenticateZendeskUser(data?.zendeskToken);
    handleSetupTokensAccess(data?.accessToken, data?.expiresAt);
    updateUserInfo(data.user);

    return {
      isAuth: true,
      userId: data?.user?._id,
      accessToken: data?.accessToken,
      persistLoading: false,
      expiresAt: new Date(data?.expiresAt),
    };
  } catch (error) {
    return handleAuthenticationError(error);
  }
};

// Função para atualizar o token de acesso
export const refreshToken = async () => {
  console.log("refreshToken!");
  if (process.env.REACT_APP_DEBUG === "true") {
    return handleDebugAuthentication();
  }

  try {
    const { data } = await AuthAPIGateway.refreshToken();
    handleSetupTokensAccess(data.accessToken, data.expiresAt);
    updateUserInfo(data.user);
    authenticateZendeskUser(data.zendeskToken);

    return {
      isAuth: true,
      userId: data.user._id,
      accessToken: data.accessToken,
      persistLoading: false,
      expiresAt: new Date(data.expiresAt),
    };
  } catch (error) {
    return handleAuthenticationError(error);
  }
};

export const setupAccountSettings = async () => {
  const { data: settings } = await HttpClient.get({
    url: "/admin/settings",
  });
  if (settings) {
    store.dispatch(updateBaseInfo({ settings }));
  }
};

// Função para tratar erros de autenticação
const handleAuthenticationError = (error) => {
  const { status, data } = error.response || {};
  const userId = data?.user?._id;

  if (status === 401) {
    const email = window.localStorage.getItem("email");
    const redirectUrl = window.location.href;
    const urlParams = new URLSearchParams({ redirect: redirectUrl });
    if (email) urlParams.append("email", email);

    window.location.replace(
      `${AUTH_BASE_URL}?step=${email ? 1 : 0}&${urlParams}`
    );
  } else {
    updateUserInfo(data?.user);
    if (status === 403) store.dispatch(setAccountError(data));
  }

  return {
    isAuth: false,
    accessToken: null,
    expiresAt: null,
    persistLoading: status === 401,
    userId,
  };
};
