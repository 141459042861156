import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { isWithinInterval } from "date-fns";
import { HttpClient } from "../../api/httpClient";
import { getDateRange } from "../../utils/more/date_functions";
import normalize from "../../utils/normalize";

const baseName = "expenses";

const adapters = {
  personal: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
  approver: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.sentAt?.localeCompare(a?.sentAt),
  }),
  financial: createEntityAdapter({
    selectId: (entity) => entity._id,
    sortComparer: (a, b) => b?.createdAt?.localeCompare(a?.createdAt),
  }),
};

const DEFAULT_ADAPTER_INITIAL_STATE = {
  status: "idle",
  error: null,
};

const initialState = {
  scanningReceipts: {},
  personal: adapters.personal.getInitialState({
    ...DEFAULT_ADAPTER_INITIAL_STATE,
    openedCount: 0,
  }),
  approver: adapters.approver.getInitialState({
    ...DEFAULT_ADAPTER_INITIAL_STATE,
  }),
  financial: adapters.financial.getInitialState({
    ...DEFAULT_ADAPTER_INITIAL_STATE,
    pendingCount: 0,
  }),
};

const isFilteredExpenses = (filters = {}) => {
  return Boolean(
    Object.keys(filters).filter(
      (filter) => Boolean(filters[filter]) && filter !== "date"
    )?.length
  );
};

export const getExpenses = createAsyncThunk(
  `${baseName}/getExpenses`,
  async ({
    role,
    filters: {
      groups,
      isRefundable,
      payments,
      categories,
      users,
      projects,
      date,
    },
    status,
    enablePeriodFilter,
    clearCache,
    signal,
  }) => {
    const { type = "this-month", meta = {} } = date || {};
    const [fromDate, toDate] = getDateRange(type, meta);

    const res = await HttpClient.get({
      url: "/expenses",
      params: {
        role,
        skip: undefined,
        status:
          role === "financial"
            ? status === "approved"
              ? ["approved"]
              : undefined
            : role === "approver"
            ? undefined
            : status === "all"
            ? undefined
            : status === "pending"
            ? ["pending", "auditing"]
            : status === "opened"
            ? ["opened", "rejected"]
            : [status],
        limit: Infinity,
        groups: groups || undefined,
        isRefundable,
        fromDate: !enablePeriodFilter ? undefined : fromDate,
        toDate: !enablePeriodFilter ? undefined : toDate,
        categories: categories || undefined,
        users: users || undefined,
        projects: projects || undefined,
        payments: payments || undefined,
      },
      shouldCache: clearCache ? false : role === "personal",
      cacheTime: 80 * 1000,
      signal,
    });
    return res?.data;
  }
);

export const expensesSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    setExpensesStatus(state, { payload }) {
      const { role, status } = payload;
      if (!role || !status) return;
      state[role].status = status;
    },
    addExpense(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].addOne(state[role], data);
      }
    },
    removeManyExpenses(state, { payload }) {
      const { role, data } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].removeMany(state[role], data);
      }
    },

    setFinancialPendingCount(state, { payload }) {
      state.financial.pendingCount = payload;
    },

    removeManyExpensesByDateRange(state, { payload }) {
      const { fromDate, toDate, role } = payload;
      if (adapters[role] && state[role]) {
        const expensesIdsToRemove = state[role]?.ids?.filter((expenseId) => {
          return isWithinInterval(
            new Date(state[role]?.entities[expenseId]?.date),
            { start: new Date(fromDate), end: new Date(toDate) }
          );
        });
        if (!expensesIdsToRemove?.length) return;
        if (role === "personal") {
          state.personal.openedCount -= expensesIdsToRemove?.length;
        }
        adapters[role].removeMany(state[role], expensesIdsToRemove);
      }
    },

    updateExpense(state, { payload }) {
      const { role, id, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateOne(state[role], { id, changes });
      }
    },
    updateManyExpenses(state, { payload }) {
      const { role, ids, changes } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateMany(
          state[role],
          ids?.map((expenseId) => ({ id: expenseId, changes }))
        );
      }
    },
    updateManyExpensesByItems(state, { payload }) {
      const { role, expenses } = payload;
      if (adapters[role] && state[role]) {
        adapters[role].updateMany(
          state[role],
          expenses?.map((expense) => ({ id: expense?._id, changes: expense }))
        );
      }
    },
    addScanningReceipts(state, { payload = [] }) {
      const { entities } = normalize(payload, "expenseId");
      state.scanningReceipts = {
        ...state.scanningReceipts,
        ...entities,
      };
    },
    removeScanningReceipt(state, { payload }) {
      if (!payload) return;
      delete state.scanningReceipts[payload];
    },
    clearCompletedScanningReceipts(state) {
      Object.keys(state.scanningReceipts).forEach((expenseId) => {
        if (state.scanningReceipts[expenseId]?.status === "completed") {
          delete state.scanningReceipts[expenseId];
        }
      });
    },
    updateScanningReceipt(state, { payload }) {
      const { id, changes = {} } = payload;
      state.scanningReceipts[id] = {
        ...state.scanningReceipts[id],
        ...changes,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpenses.pending, (state, action) => {
        const { role } = action.meta.arg;
        state[role].status = "loading";
      })
      .addCase(getExpenses.rejected, (state, action) => {
        const { role } = action.meta.arg;
        if (!state[role]) return;
        state[role].status = "failed";
        state[role].error =
          action.error.message || "Não foi possível buscar as despesas";
      })
      .addCase(getExpenses.fulfilled, (state, action) => {
        const {
          role,
          updateFinancialPendingCount,
          updatePersonalOpenedCount,
          filters,
        } = action.meta.arg;

        const { expenses } = action.payload || [];
        if (!state[role]) return;
        state[role].status = "succeeded";
        const { entities } = normalize(expenses);
        adapters[role].setAll(state[role], entities);

        //total updaters
        if (!isFilteredExpenses(filters)) {
          if (updatePersonalOpenedCount) {
            state.personal.openedCount = expenses?.length;
          }
          if (updateFinancialPendingCount) {
            const pendingExpenses = expenses?.filter(
              (exp) => exp?.status === "approved"
            );
            state.financial.pendingCount = pendingExpenses?.length || 0;
          }
        }
      });
  },
});

export const {
  addExpense,
  updateExpense,
  removeManyExpenses,
  removeManyExpensesByDateRange,
  updateManyExpenses,
  addScanningReceipts,
  removeScanningReceipt,
  updateScanningReceipt,
  setFinancialPendingCount,
  clearCompletedScanningReceipts,
  setExpensesStatus,
  updateManyExpensesByItems,
} = expensesSlice.actions;

export const expensesSelectors = {
  personal: adapters.personal.getSelectors(
    (state) => state[baseName]?.personal
  ),
  approver: adapters.approver.getSelectors(
    (state) => state[baseName]?.approver
  ),
  financial: adapters.financial.getSelectors(
    (state) => state[baseName]?.financial
  ),
};

export const selectAllScanningReceipts = createSelector(
  [(state) => state.expenses.scanningReceipts],
  (receiptsObj) => {
    return Object.keys(receiptsObj)?.map((receiptId) => ({
      ...receiptsObj[receiptId],
    }));
  }
);

export default expensesSlice.reducer;
