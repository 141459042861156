import {
  DeleteOutlineOutlined,
  MoreVert,
  SendOutlined,
} from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import RemoveExpensesWrapper from "../../../../../../actions/expenses/RemoveExpensesWrapper";
import CopyModel from "./CopyModel";
import SendConfirmationWrapper from "./SendConfirmationWrapper";

const MoreButton = ({
  isEditable,
  expenseId,
  role,
  originalValuesRef,
  onSendToApproval,
  disabled,
  onClose,
}) => {
  const [menu, setMenu] = useState(null);
  const closeMenu = useCallback(() => setMenu(null), []);
  return (
    <>
      <IconButton onClick={(e) => setMenu(e.target)}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={closeMenu}
        sx={{
          "& .MuiMenuItem-root": {
            height: 50,
            fontSize: ".96rem",
            fontWeight: "600",
          },
        }}
      >
        {isEditable && (
          <SendConfirmationWrapper
            expensesIds={[expenseId]}
            onSendToApproval={onSendToApproval}
            renderComponent={({ openModal }) => (
              <MenuItem disabled={disabled} color="primary" onClick={openModal}>
                <SendOutlined sx={{ mr: 2 }} color="action" />
                Enviar para aprovação
              </MenuItem>
            )}
          />
        )}
        <CopyModel
          disabled={disabled}
          originalValuesRef={originalValuesRef}
          closeMenu={closeMenu}
        />
        {isEditable && (
          <RemoveExpensesWrapper
            onClose={onClose}
            expensesIds={[expenseId]}
            role={role}
            renderComponent={({ openModal }) => (
              <MenuItem disabled={disabled} onClick={openModal}>
                <DeleteOutlineOutlined sx={{ mr: 2 }} color="action" />
                Excluir
              </MenuItem>
            )}
          />
        )}
      </Menu>
    </>
  );
};

export default MoreButton;
