import {
  CheckCircle,
  CheckCircleOutlineOutlined,
  Checklist,
  CreditScore,
  CreditScoreOutlined,
  HistoryOutlined,
  ThumbDownAlt,
  ThumbDownAltOutlined,
  Wallet,
  WalletOutlined,
} from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React, { memo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import useRouteContext from "../../../../../hooks/useRouteContext";
import SidebarRoundedItem from "../../../components/SidebarRoundedItem";
import CreationFab from "./components/CreationFab";

const AdvancesSidebar = (props) => {
  const { role } = useRouteContext();

  const [params, setParams] = useSearchParams();

  const personalFilter = params.get("advancesFilter") || "all";
  const advancesFinancialFilter =
    params.get("advancesFinancialFilter") || "approved";

  const advancesFilter =
    role === "financial" ? advancesFinancialFilter : personalFilter;

  const handleFilterChange = useCallback(
    (filter) => () => {
      if (role === "financial") {
        params.set("advancesFinancialFilter", filter);
        setParams(params);
        return;
      }
      params.set("advancesFilter", filter);
      setParams(params);
    },
    [params, role]
  );

  if (!role) return;

  return (
    <>
      <Box mb={2} pl={0.1}>
        <CreationFab role={role} />
      </Box>
      <div>
        {role === "financial" && (
          <>
            <SidebarRoundedItem
              disableLink
              isSelected={advancesFilter === "approved"}
              Icon={Checklist}
              ActiveIcon={Checklist}
              title={"Para pagar"}
              onClick={handleFilterChange("approved")}
            />
            <Divider sx={{ my: 1 }} />
          </>
        )}
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "all"}
          Icon={WalletOutlined}
          ActiveIcon={Wallet}
          title={"Todos"}
          onClick={handleFilterChange("all")}
        />
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "available"}
          Icon={CreditScoreOutlined}
          ActiveIcon={CreditScore}
          title={"Disponíveis"}
          onClick={handleFilterChange("available")}
        />
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "pending"}
          Icon={HistoryOutlined}
          title={"Em aprovação"}
          onClick={handleFilterChange("pending")}
        />
        {role !== "financial" && (
          <SidebarRoundedItem
            disableLink
            isSelected={advancesFilter === "approved"}
            Icon={CheckCircleOutlineOutlined}
            ActiveIcon={CheckCircle}
            title={"Aprovados"}
            onClick={handleFilterChange("approved")}
          />
        )}
        <SidebarRoundedItem
          disableLink
          isSelected={advancesFilter === "rejected"}
          Icon={ThumbDownAltOutlined}
          ActiveIcon={ThumbDownAlt}
          title={"Rejeitados"}
          onClick={handleFilterChange("rejected")}
        />
        {/* <Divider sx={{ my: 1 }} />
        <Box px={2} pr={0}>
          <FormControlLabel
            label="Com valor disponível"
            control={<Checkbox sx={{ mr: 1 }} />}
          />
          <FormControlLabel
            label="Com limite atingido"
            control={<Checkbox sx={{ mr: 1 }} />}
          />
        </Box> */}
      </div>
    </>
  );
};

export default memo(AdvancesSidebar);
