import {
  AccessTimeFilled,
  CheckCircle,
  CheckCircleOutlineOutlined,
  CurrencyExchange,
  CurrencyExchangeOutlined,
  HistoryOutlined,
  ReceiptLong,
  ReceiptLongOutlined,
  Send,
  SendOutlined,
  ThumbDownAlt,
  ThumbDownAltOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CreationButton from "../../../../components/CreationButton";
import SidebarRoundedItem from "../../../../components/SidebarRoundedItem";

const ExpensesSidebarPessoal = () => {
  const [params, setParams] = useSearchParams();
  const [selectedFilter, setSelectedFilter] = useState(() => {
    return params.get("expensesFilter") || "opened";
  });

  const openedCount = useSelector(
    (state) => state.expenses.personal.openedCount
  );

  useEffect(() => {
    // Sincronize o estado com os parâmetros da URL
    setSelectedFilter(params.get("expensesFilter") || "opened");
  }, [params]);

  const handleFilterChange = useCallback(
    (filter) => () => {
      setSelectedFilter(filter);
      params.set("expensesFilter", filter);
      setParams(params);
      localStorage.setItem("expensesFilter", filter);
    },
    [params, setParams]
  );

  return (
    <div>
      <Box
        mb={2}
        pl={0.1}
        width={"100%"}
        gap={2}
        display={"flex"}
        alignItems={"center"}
      >
        <CreationButton />
      </Box>

      <SidebarRoundedItem
        disableLink
        isSelected={selectedFilter === "opened"}
        Icon={SendOutlined}
        ActiveIcon={Send}
        title={"Para enviar"}
        onClick={handleFilterChange("opened")}
        content={
          Boolean(openedCount > 0) && (
            <Box
              px={1}
              mr={-1}
              borderRadius={100}
              bgcolor={(t) =>
                selectedFilter === "opened"
                  ? "transparent"
                  : `${t.palette.primary.main}25`
              }
            >
              <Typography
                fontSize={".85rem"}
                color={"text.primary"}
                fontWeight={"600"}
              >
                {openedCount}
              </Typography>
            </Box>
          )
        }
      />
      <SidebarRoundedItem
        disableLink
        isSelected={selectedFilter === "pending"}
        Icon={HistoryOutlined}
        ActiveIcon={AccessTimeFilled}
        title={"Em aprovação"}
        onClick={handleFilterChange("pending")}
      />
      <SidebarRoundedItem
        disableLink
        isSelected={selectedFilter === "rejected"}
        Icon={ThumbDownAltOutlined}
        ActiveIcon={ThumbDownAlt}
        title={"Rejeitadas"}
        onClick={handleFilterChange("rejected")}
      />
      <SidebarRoundedItem
        disableLink
        isSelected={selectedFilter === "approved"}
        Icon={CheckCircleOutlineOutlined}
        ActiveIcon={CheckCircle}
        title={"Aprovadas"}
        onClick={handleFilterChange("approved")}
      />
      <SidebarRoundedItem
        disableLink
        isSelected={selectedFilter === "finished"}
        Icon={CurrencyExchangeOutlined}
        ActiveIcon={CurrencyExchange}
        title={"Finalizadas"}
        onClick={handleFilterChange("finished")}
      />
      <SidebarRoundedItem
        disableLink
        isSelected={selectedFilter === "all"}
        Icon={ReceiptLongOutlined}
        ActiveIcon={ReceiptLong}
        title={"Todas as despesas"}
        onClick={handleFilterChange("all")}
      />
    </div>
  );
};

export default memo(ExpensesSidebarPessoal);
