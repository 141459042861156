import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

function NoDataContent({
  Icon,
  subtitle,
  title,
  height = 300,
  iconSize = "4rem",
  titleSize = "1.5rem",
  component,
  children,
  px = 6,
}) {
  return (
    <Box
      component={component}
      px={px}
      height={height}
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
      flexDirection="column"
    >
      {Icon && (
        <Icon color="action" sx={{ fontSize: iconSize, opacity: 0.4, mb: 2 }} />
      )}
      <Typography
        fontWeight={"600"}
        textAlign={"center"}
        fontSize={titleSize}
        color={"text.primary"}
      >
        {title}
      </Typography>
      <Typography
        mt={0.4}
        maxWidth={400}
        textAlign={"center"}
        fontWeight={"400"}
        fontSize={"1rem"}
        component={'div'}
        color={"text.secondary"}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
      </Typography>
      {Boolean(children) && <Box mt={2}>{children}</Box>}
    </Box>
  );
}

export default memo(NoDataContent);
