import { SendOutlined } from "@mui/icons-material";
import { Box, Button, DialogActions, Skeleton } from "@mui/material";
import React from "react";
import ApproveButton from "../../../components/buttons/ApproveButton";
import RejectButton from "../../../components/buttons/RejectButton";
import SendConfirmationWrapper from "./components/header/components/SendConfirmationWrapper";

const ActionsContent = ({
  loading,
  saving,
  changed,
  expenseId,
  onClose = () => {},
  onSend = () => {},
  onSave = () => {},
  isEditable,
  hasPermissionToEdit,
  isApprover
}) => {
  return (
    <DialogActions
      sx={{
        boxShadow: 4,
        height: 55,
        "& .MuiButtonBase-root": { height: 35 },
      }}
    >
      {loading ? (
        <>
          <Skeleton
            height={30}
            variant="rounded"
            sx={{ borderRadius: 100 }}
            width={100}
          />
          <Skeleton
            height={30}
            variant="rounded"
            sx={{ borderRadius: 100 }}
            width={100}
          />
          <Box flex={1} />
        </>
      ) : (
        <>
          {isApprover ? (
            <>
              <ApproveButton
                ids={[expenseId]}
                onApprove={onClose}
                type="expenses"
              />
              <RejectButton
                ids={[expenseId]}
                onApprove={onClose}
                type="expenses"
              />
              <Box flex={1} />
              <Button onClick={onClose}>Cancelar</Button>
            </>
          ) : (
            <>
              {hasPermissionToEdit && (
                <>
                  <Button
                    onClick={onSave}
                    disabled={!changed || loading || saving}
                    sx={{ px: 4 }}
                    variant="contained"
                    disableElevation
                  >
                    {saving ? "Salvando" : "Salvar"}
                  </Button>
                  <Button onClick={onClose}>Cancelar</Button>
                  <Box flex={1} />
                  {isEditable && (
                    <SendConfirmationWrapper
                      onSendToApproval={onSend}
                      expensesIds={[expenseId]}
                      renderComponent={({ openModal }) => (
                        <Button
                          disabled={loading || saving}
                          onClick={openModal}
                          endIcon={<SendOutlined />}
                          variant="outlined"
                        >
                          Enviar
                        </Button>
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </DialogActions>
  );
};

export default ActionsContent;
