import { ArrowBack, HelpOutlineOutlined } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React, { lazy, memo, Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRouteContext from "../../hooks/useRouteContext";
import { selectIsPersonalOnly } from "../../store/features/accountSlice";
import rolesSettings from "../../utils/rolesSettings";
import AccountIndicator from "../account-indicator/AccountIndicator";
import NotepayLogo from "../NotepayLogo";
import NotificationsMain from "./components/notifications/NotificationsMain";
import ScannerButton from "./components/ScannerButton";
import SettingsButton from "./components/SettingsButton";

const ToggleProfileType = lazy(() => import("./toggle-profile-type"));
const SearchBar = lazy(() => import("../search/SearchBar"));

function Appbar(props) {
  const navigate = useNavigate();
  const { title, routeId } = useRouteContext();

  const isPersonalOnly = useSelector(selectIsPersonalOnly);

  const showToggleRole = useMemo(
    () => rolesSettings[routeId]?.hasProfiles && !isPersonalOnly,
    [routeId, isPersonalOnly]
  );

  return (
    <AppBar
      id="appbar_main"
      variant="none"
      color="transparent"
      position="relative"
      sx={{
        width: "100%",
        transition: "none",
        zIndex: (t) => t.zIndex.appBar + 30,
      }}
    >
      <Toolbar disableGutters sx={{ px: 2 }}>
        <Box
          pr={1}
          display={"flex"}
          alignItems={"center"}
          width={showToggleRole || routeId === "admin" ? "13.5em" : "auto"}
        >
          <Box width={45}>
            {routeId === "settings" ? (
              <IconButton
                onClick={() =>
                  navigate(localStorage.getItem("settings_prev_route") || "/")
                }
                color="inherit"
              >
                <ArrowBack sx={{ fontSize: "26px" }} />
              </IconButton>
            ) : (
              <NotepayLogo />
            )}
          </Box>
          <Typography
            ml={0.3}
            fontWeight={"500"}
            color={"text.primary"}
            fontSize={"1.4rem"}
            sx={{ opacity: 0.8 }}
            noWrap
          >
            {title}
          </Typography>
        </Box>
        <Suspense>
          {showToggleRole && <ToggleProfileType />}
          {routeId === "admin" && <SearchBar />}
        </Suspense>
        <Box
          flex={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          {routeId !== "settings" && <ScannerButton />}
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
          <SettingsButton />
          <NotificationsMain />
          <AccountIndicator />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default memo(Appbar);
