import {
  Close,
  SettingsOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthClient } from "../../../../api/httpClient";
import {
  selectIsMaster,
  setHasPhoto,
  updatePhotoVersion,
} from "../../../../store/features/accountSlice";
import { setError } from "../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../store/features/base/snackbarBaseSlice";
import { getBase64 } from "../../../../utils/more/images_transform";
import AvatarPicker from "./components/AvatarPicker";
import SectionActionButton from "./components/SectionActionButton";
import SectionActionPaper from "./components/SectionActionPaper";
import SectionPaper from "./components/SectionPaper";

function BaseInfo({
  name,
  lastname,
  userId,
  email,
  profilePhotoSrc,
  closeMenu,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMaster = useSelector(selectIsMaster);

  const handleChangeUserPhoto = async (photo) => {
    const base64 = await getBase64(photo);
    try {
      const { data } = await AuthClient.client().put(`/profile`, {
        profilePicBase64: base64,
      });
      fetch(profilePhotoSrc, {
        headers: {
          "force-revalidate": 1,
        },
      }).then(() => {
        dispatch(updatePhotoVersion(data?.profile_photo_version));
      });
      dispatch(setHasPhoto(true));
      dispatch(openSnackbar({ message: "Foto salva" }));
    } catch (error) {
      dispatch(setError({ title: "Erro ao salvar foto de perfil", error }));
    }
  };
  const handleRemoveUserPhoto = async (photo) => {
    try {
      const { data } = await AuthClient.client().put(`/profile`, {
        deleteProfilePic: true,
      });
      fetch(profilePhotoSrc, {
        headers: {
          "force-revalidate": 1,
        },
      }).then(() => {
        dispatch(updatePhotoVersion(data?.profile_photo_version));
      });
      dispatch(setHasPhoto(false));
      dispatch(openSnackbar({ message: "Foto removida" }));
    } catch (error) {
      dispatch(setError({ title: "Erro ao remover foto de perfil", error }));
    }
  };

  return (
    <>
      <SectionPaper
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          onClick={closeMenu}
          disableTouchRipple
          size="small"
          sx={{
            position: "absolute",
            top: 9,
            right: 9,
            "& .MuiSvgIcon-root": {
              opacity: 0.7,
            },
            ":hover": {
              "& .MuiSvgIcon-root": {
                opacity: 1,
              },
            },
          }}
        >
          <Close sx={{ fontSize: "24px" }} />
        </IconButton>
        <AvatarPicker
          name={name}
          userId={userId}
          photo={profilePhotoSrc || null}
          onChange={handleChangeUserPhoto}
          onRemove={handleRemoveUserPhoto}
          showAlertRemove
          closeOnChange={false}
        />
        <Box ml={2} width={"100%"}>
          <Box display={"flex"} alignItems={"center"}>
            <Box flex={1}>
              <Typography fontWeight={"600"} fontSize={"1.2rem"}>
                {name} {lastname || ""}
              </Typography>
              <Typography
                sx={{ wordBreak: "break-all" }}
                fontSize={".85rem"}
                color={"text.secondary"}
              >
                {email}
              </Typography>
            </Box>
          </Box>
          {isMaster && (
            <Typography
              display={"flex"}
              alignItems={"center"}
              fontSize={".8rem"}
              color={"text.primary"}
              fontWeight={"600"}
              mt={0.5}
            >
              <WorkspacePremiumOutlined
                color="primary"
                sx={{ fontSize: ".9rem", mr: 1 }}
              />
              Proprietário
            </Typography>
          )}
        </Box>
      </SectionPaper>
      <SectionActionPaper>
        <SectionActionButton
          onClick={() => {
            navigate("/settings");
            closeMenu();
          }}
          Icon={SettingsOutlined}
        >
          Configurações pessoais
        </SectionActionButton>
      </SectionActionPaper>
    </>
  );
}

export default memo(BaseInfo);
