import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../api/actions";
import TextInput from "../../components/inputs/text-input";
import { removeManyAdvances } from "../../store/features/advancesSlice";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { removeManyExpenses } from "../../store/features/expensesSlice";

const role = "approver";

const RejectModal = ({
  open,
  onClose = () => {},
  ids = [],
  onApprove = () => {},
  type = "expenses",
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (!open) {
      setComment("");
    }
  }, [open]);

  const handleReject = async () => {
    setLoading(true);
    try {
      if (type === "expenses") {
        await APIActions.expenses.reject({
          expensesIds: ids,
          role,
          comment,
        });
        dispatch(
          removeManyExpenses({
            role,
            data: ids,
          })
        );
        dispatch(
          openSnackbar({
            message:
              ids?.length === 1
                ? "Despesa rejeitada"
                : `${ids?.length} despesas rejeitadas`,
          })
        );
      }
      if (type === "advances") {
        await APIActions.advances.reject({
          advancesIds: ids,
          role,
          comment,
        });
        dispatch(
          removeManyAdvances({
            role,
            data: ids,
          })
        );
        dispatch(
          openSnackbar({
            message:
              ids?.length === 1
                ? "Adiantamento rejeitado"
                : `${ids?.length} adiantamentos rejeitados`,
          })
        );
      }

      onClose();
      onApprove();
    } catch (error) {
      dispatch(
        setError({
          error,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      open={open}
      onClose={() => !comment?.trim() && onClose()}
      fullWidth
      maxWidth="xs"
    >
      {loading && <LinearProgress color="error" />}
      <DialogTitle fontSize={"1.3rem"} fontWeight={"600"}>
        {type === "expenses" && (
          <>
            {ids?.length === 1
              ? `Deseja rejeitar a despesa?`
              : `Deseja rejeitar ${ids?.length} despesas?`}
          </>
        )}
        {type === "advances" && (
          <>
            {ids?.length === 1
              ? `Deseja rejeitar o adiantamento?`
              : `Deseja rejeitar ${ids?.length} adiantamentos?`}
          </>
        )}
        <Box flex={1} />
        <IconButton onClick={onClose} sx={{ m: -1 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextInput
          value={comment}
          onChange={setComment}
          multiline
          rows={6}
          margin={"normal"}
          autoFocus
          placeholder={"Informe o motivo"}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          disabled={loading}
          variant="contained"
          disableElevation
          onClick={handleReject}
        >
          {loading ? "Rejeitando" : "Rejeitar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(RejectModal);
