import { SendOutlined } from "@mui/icons-material";
import { Box, Button, DialogActions } from "@mui/material";
import React from "react";

const ActionsFooter = ({
  onClose = () => {},
  onCreate = () => {},
  onSendToApproval = () => {},
  creating,
  sending,
}) => {
  return (
    <DialogActions sx={{ boxShadow: 4 }}>
      <Button
        sx={{ px: 4 }}
        onClick={onCreate}
        variant="contained"
        disableElevation
        disabled={creating}
      >
        {creating ? "Criando" : "Criar"}
      </Button>
      <Button onClick={onClose}>Cancelar</Button>
      <Box flex={1} />
      <Button
        endIcon={<SendOutlined />}
        variant="outlined"
        disableElevation
        disabled={sending}
        onClick={onSendToApproval}
      >
        {sending ? "Enviando" : "Enviar"}
      </Button>
    </DialogActions>
  );
};

export default ActionsFooter;
