import {
  Circle,
  ExpandLess,
  ExpandMore,
  NotesOutlined,
} from "@mui/icons-material";
import { Box, Collapse, IconButton, ListItem, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import Baseline from "../../../../components/form/Baseline";
import NotepayLogo from "../../../../components/NotepayLogo";
import UserListItem from "../../../../components/USERS/UserListItem";

const RejectedInfo = ({ rejectedBy = {} }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Box flex={1}>
          <Typography
            fontSize={"1.2rem"}
            fontWeight={"600"}
            display={"flex"}
            alignItems={"center"}
            component={"div"}
            gap={1}
          >
            <Circle color="error" sx={{ fontSize: ".5rem" }} />
            Rejeitada {rejectedBy?.step ? `na etapa ${rejectedBy?.step}` : ""}
          </Typography>
        </Box>
        <IconButton
          sx={{ m: -1 }}
          disableTouchRipple
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box pl={0.6} mt={1}>
          <Typography fontSize={".8rem"} color={"text.secondary"}>
            Aprovador:
          </Typography>
          {rejectedBy?.fromAuditor ? (
            <ListItem disableGutters>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={45}
              >
                <NotepayLogo sx={{ width: 40 }} />
              </Box>
              <Typography ml={0.5} fontWeight={"600"} fontSize={".95rem"}>
                Auditor Notepay
              </Typography>
            </ListItem>
          ) : (
            <UserListItem
              sx={{ ml: -2 }}
              clickable={false}
              dense
              user={rejectedBy?.user || {}}
            />
          )}
          <Baseline
            sx={{ ml: -0.5, mt: 1, alignItems: "flex-start" }}
            Icon={NotesOutlined}
            label={"Motivo"}
            value={rejectedBy?.comment}
            emptyLabel={"Nenhum motivo informado"}
            valueSx={{
              lineHeight: 1.25,
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default memo(RejectedInfo);
