import { InfoOutlined, LocationOnOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { HttpClient } from "../../../../api/httpClient";
import Baseline from "../../../../components/form/Baseline";
import { capitalizeFirstLetter } from "../../../../utils/formaters";
import SelectorBox from "../SelectorBox";

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const InfoButton = memo(({ value = {} }) => {
  return (
    <Tooltip
      arrow
      placement="bottom-end"
      title={
        <>
          <Typography
            fontWeight={"500"}
            fontSize={".85rem"}
            sx={{ opacity: 0.8 }}
            variant="body2"
          >
            Local
          </Typography>
          <Typography fontWeight={"600"} variant="body2">
            {value?.name}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            fontWeight={"500"}
            fontSize={".85rem"}
            sx={{ opacity: 0.8 }}
            variant="body2"
          >
            Endereço
          </Typography>
          <Typography fontWeight={"600"} variant="body2">
            {value?.address}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            fontWeight={"500"}
            fontSize={".85rem"}
            sx={{ opacity: 0.8 }}
            variant="body2"
          >
            Cidade/Estado/País
          </Typography>
          <Typography fontWeight={"600"} variant="body2">
            {value?.city} / {value?.state} / {value?.country}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            fontWeight={"500"}
            fontSize={".85rem"}
            sx={{ opacity: 0.8 }}
            variant="body2"
          >
            Destino
          </Typography>
          <Typography fontWeight={"600"} variant="body2">
            {capitalizeFirstLetter(value?.destination)}
          </Typography>
        </>
      }
    >
      <IconButton size="small" color="primary">
        <InfoOutlined fontSize="small" sx={{ fontSize: "1.2rem" }} />
      </IconButton>
    </Tooltip>
  );
});

const LocalInput = ({
  value,
  variant,
  size,
  inputRef,
  isEditable,
  onChange = () => {},
  clearError = () => {},
  readOnly,
  categoryType = null,
  margin = "dense",
  required,
  error,
  helperText,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetch = React.useMemo(
    () =>
      debounce(async (text, callback) => {
        setLoading(true);
        HttpClient.get({
          url: `/expenses/places/search`,
          params: {
            text,
            placeType: categoryType,
          },
          shouldCache: true,
        })
          .then(({ data: responseData }) => {
            callback(Array.isArray(responseData) ? responseData : []);
          })
          .finally(() => setLoading(false));
      }, 400),
    [categoryType]
  );

  const renderPopper = useCallback((props) => {
    return <Popper {...props} style={styles.popper} />;
  }, []);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setData(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (responseData) => {
      if (active) {
        if (value && !responseData?.find((place) => place?.id === value?.id)) {
          setData([value, ...responseData]);
        } else {
          setData(responseData);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  if (!isEditable) {
    return (
      <Baseline
        Icon={(props) => <LocationOnOutlined {...props} sx={{ mt: 0.7 }} />}
        label={"Local"}
        sx={{ alignItems: "flex-start" }}
        value={
          value && (
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <Box>
                <Typography variant="inherit">{value?.name}</Typography>
                {(value?.city || value?.state || value?.country) && (
                  <Typography
                    fontWeight={"500"}
                    variant="body2"
                    color="textSecondary"
                  >
                    {value?.city}
                    {value?.state ? `, ${value?.state}` : ""}
                    {value?.country ? ` - ${value?.country}` : ""}
                  </Typography>
                )}
              </Box>
              <InfoButton value={value} />
            </Stack>
          )
        }
        emptyLabel={"Não informado"}
      />
    );
  } else {
    return (
      <SelectorBox Icon={LocationOnOutlined}>
        <Autocomplete
          size={size || "small"}
          autoHighlight
          fullWidth
          autoComplete
          loading={loading}
          openOnFocus={false}
          readOnly={readOnly}
          options={data || []}
          value={value || null}
          filterOptions={(options, state) => options || []}
          PopperComponent={renderPopper}
          onChange={(event, newValue) => {
            clearError("place");
            onChange(newValue);
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          renderOption={(props, option, { selected }) => {
            return (
              <MenuItem
                {...props}
                sx={{ height: 70, gap: 2, alignItems: "center" }}
                key={option?.id}
                selected={selected}
              >
                <LocationOnOutlined color="primary" />
                <Box>
                  <Stack gap={1} direction={"row"} alignItems={"center"}>
                    <Typography variant="inherit" fontWeight={"600"}>
                      {option?.name}
                    </Typography>
                  </Stack>
                  <Typography noWrap variant="body2" color={"text.secondary"}>
                    {option?.address}
                  </Typography>
                </Box>
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="place"
              error={error}
              margin={margin}
              required={required}
              helperText={helperText}
              inputRef={inputRef}
              fullWidth
              variant={variant}
              placeholder="Hotéis, restaurantes e mais"
              label={"Local"}
            />
          )}
        />
      </SelectorBox>
    );
  }
};

export default memo(LocalInput);
