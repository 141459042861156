import { Button } from "@mui/material";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../api/actions";
import DialogAlert from "../../classes/DialogAlert";
import { removeManyAdvances } from "../../store/features/advancesSlice";
import { setError } from "../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../store/features/base/snackbarBaseSlice";
import { removeManyExpenses } from "../../store/features/expensesSlice";

const role = "approver";

const ApproveButton = ({
  ids = [],
  onApprove = () => {},
  type = "expenses",
}) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();
    DialogAlert.show({
      disableCloseButton: true,
      title:
        type === "expenses"
          ? ids?.length > 1
            ? `Aprovar ${ids.length} despesas`
            : "Aprovar despesa"
          : ids?.length > 1
          ? `Aprovar ${ids.length} adiantamentos`
          : "Aprovar adiantamento",
      actions: [
        { text: "Cancelar" },
        {
          main: true,
          text: "Aprovar",
          onClick: async () => {
            try {
              if (type === "expenses") {
                await APIActions.expenses.approve({
                  expensesIds: ids,
                  role,
                });
                dispatch(
                  openSnackbar({
                    message:
                      ids?.length === 1
                        ? "Despesa aprovada"
                        : `${ids?.length} despesas aprovadas`,
                  })
                );
                dispatch(
                  removeManyExpenses({
                    data: ids,
                    role,
                  })
                );
              } else if (type === "advances") {
                await APIActions.advances.approve({
                  advancesIds: ids,
                  role,
                });
                dispatch(
                  openSnackbar({
                    message:
                      ids?.length === 1
                        ? "Adiantamento aprovado"
                        : `${ids?.length} adiantamentos aprovados`,
                  })
                );
                dispatch(
                  removeManyAdvances({
                    data: ids,
                    role,
                  })
                );
              }
              onApprove();
            } catch (error) {
              dispatch(
                setError({
                  error,
                })
              );
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <Button
        sx={{
          height: 33,
          fontSize: ".95rem",
          transition: "none",
        }}
        onClick={handleClick}
        color="primary"
        variant="contained"
        disableElevation
      >
        Aprovar {ids.length > 1 ? `(${ids?.length})` : ""}
      </Button>
    </>
  );
};
export default memo(ApproveButton);
