import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadScript } from "@react-google-maps/api";
import { ptBR } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// STYLES
import "./App.css";

// CONTEXTS

// COMPONENTS
import DialogAlert from "./classes/DialogAlert";
import SnackbarBase from "./components/snackbar/SnackbarBase";
import ModalErrorBase from "./modals/error/ModalErrorBase";
import Router from "./routes/router";

// REDUX ACTIONS
import { getCategories } from "./store/features/configs/categoriesSlice";
import { getPayments } from "./store/features/configs/paymentsSlice";
import { getProjects } from "./store/features/configs/projectsSlice";

// API & UTILITIES
import { refreshToken, setupAccountSettings } from "./api/auth";
import WebSockets from "./api/websockets";
import theme from "./theme";

// CONSTANTS
const librariesGoogleMapsApi = ["places"];
const accessTokenRefreshTime = 420000; // 7 minutos

function App() {
  const dispatch = useDispatch();
  const mode = "light";
  const [isAuth, setIsAuth] = useState(false);

  const checkIsNewAccount = useCallback(
    (userId) => {
      const prevUserId = window.localStorage.getItem("userId");
      if (prevUserId && prevUserId !== userId) {
        dispatch({ type: "RESET_STORE" });
      }
      window.localStorage.setItem("userId", userId);
    },
    [dispatch]
  );

  const startInitialDispatchers = useCallback(() => {
    dispatch(getCategories("personal"));
    dispatch(getProjects("personal"));
    dispatch(getPayments("personal"));
  }, []);

  const removeLoadingApplication = useCallback(() => {
    const loadingElement = document.getElementById("load_application");
    loadingElement?.parentNode?.removeChild(loadingElement);
  }, []);

  const refreshFunction = async () => {
    await refreshToken();
    await setupAccountSettings();
  };

  const startInitialConfiguration = async () => {
    const { isAuth: authSuccess, userId } = await refreshToken();
    if (authSuccess) {
      await setupAccountSettings();
      checkIsNewAccount(userId);
      setIsAuth(true);
      WebSockets.start();
      startInitialDispatchers();
      setInterval(refreshFunction, accessTokenRefreshTime);
      removeLoadingApplication();
    }
  };

  useEffect(() => {
    startInitialConfiguration();
    return () => clearInterval(refreshFunction);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = mode === "dark" ? "#202124" : "#FFF";
    document.body.classList.toggle("dark-mode", mode === "dark");
    document.body.classList.toggle("light-mode", mode !== "dark");
  }, [mode]);

  // Zendesk webchat settings
  useEffect(() => {
    window.zE?.("messenger:on", "close", () => {
      setTimeout(() => window.zE("messenger", "hide"), 200);
    });
  }, []);

  if (!isAuth) return;

  return (
    <ThemeProvider theme={theme(mode)}>
      <div className="App">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={librariesGoogleMapsApi}
        >
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <BrowserRouter>
              <Router />
            </BrowserRouter>
            <ModalErrorBase />
            <SnackbarBase />
            <DialogAlert />
          </LocalizationProvider>
        </LoadScript>
      </div>
    </ThemeProvider>
  );
}

export default App;
