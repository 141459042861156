import { NotesOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { memo } from "react";
import Baseline from "../../../../components/form/Baseline";
import SelectorBox from "../SelectorBox";

function ObsInput({
  value = "",
  size,
  readOnly,
  onChange,
  variant = "filled",
  isEditable = true,
  margin = "dense",
  required,
  helperText,
  error,
  clearError = () => {},
}) {
  if (!isEditable) {
    return (
      <Baseline
        Icon={NotesOutlined}
        label={"Descrição"}
        value={value}
        emptyLabel={"Sem descrição"}
      />
    );
  } else {
    return (
      <SelectorBox
        iconSx={{ mt: 1 }}
        sx={{ alignItems: "flex-start" }}
        Icon={NotesOutlined}
      >
        <TextField
          label={"Descrição"}
          fullWidth
          readOnly={readOnly}
          variant={variant}
          name="notes"
          size={"small"}
          margin={margin}
          required={required}
          value={value}
          onChange={(e) => {
            clearError("notes");
            onChange(e.target.value);
          }}
          multiline
          rows={4}
          error={error}
          helperText={helperText}
        />
      </SelectorBox>
    );
  }
}

export default memo(ObsInput);
